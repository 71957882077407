.linkList {
    color: antiquewhite;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4vh;
    
}
.link {
    margin-top: 30px;
    font-size: 80px;
    padding: 5px 5px;
    cursor: pointer;
    margin: 3px;
    margin-bottom: 20px;
    border-radius: 10px;
    width: 500px;
    height: 100px;
    display: block;
    position: static;

}
.hyperlink{
    color: antiquewhite;
    text-decoration: none;
}