* {
  background-color: #282c34;
}

.body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1 0 auto;
  padding-bottom: 60px;
  overflow-y: auto;
}