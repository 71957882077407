.ruleList {
    color: antiquewhite;
    font-size: 20px;
    display: inline-block;
    margin: 30px;
    
    
}
.letItRip {
    text-align: center;
    font-size: 40px;
    color: red;
}
.triviaTop{   
    margin-left: 30px;
    font-size: 30px;
    margin-bottom: 50px;
}