.links {
    height: 10px;
    margin-bottom: 15px;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.dakiLogo {
    width: 50%;
    min-width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

.buttonStyle {
    margin-top: 30px;
    font-size: 20px;
    padding: 5px 5px;
    cursor: pointer;
    margin: 3px;
    border-radius: 10px;
    margin-bottom: 40px;
}

.linkButton {
    color: antiquewhite;
    text-decoration: none;
    font-size: 20px;
}