.Instagram{   
    margin-top: 5%;
    text-align: center;
    font-size: 50px;
    color: antiquewhite;
}

.registrationLink{
    color: antiquewhite;
    text-decoration:none;
}

.instaLogo {
    width: 50px;
}

a:hover{
    color: rgb(170, 2, 2);
}

/*color: #21425d;*/