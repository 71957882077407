.faqList {
    color: antiquewhite;
    font-size: 20px;
    display: flex;
    margin: 20px;

}

.mapsIcon {
    width: 20px;
    height: 20px;
}

.foodIntro {
    margin: 30px;
}

.foodList {
    list-style: none;
}

.smallText {
    margin: 20px;
    font-size: 10px;
}

.title {
    text-align: center;
}